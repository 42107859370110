import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Software/Home_Automation/PrimaryBox';
import PushHostAlarm from 'components/Software/Home_Automation/CGI_Galleries/PushHostAlarm';
import SetInfrared from 'components/Software/Home_Automation/CGI_Galleries/SetInfrared';
import GoToPreset from 'components/Software/Home_Automation/CGI_Galleries/GoToPreset';
import SetMdAttr from 'components/Software/Home_Automation/CGI_Galleries/SetMdAttr';
import SetMdAttr2 from 'components/Software/Home_Automation/CGI_Galleries/SetMdAttr2';
import SetMdAttr3 from 'components/Software/Home_Automation/CGI_Galleries/SetMdAttr3';
import GoToPreset2 from 'components/Software/Home_Automation/CGI_Galleries/GoToPreset2';
import ManualRec from 'components/Software/Home_Automation/CGI_Galleries/ManualRec';
import GoToPreset3 from 'components/Software/Home_Automation/CGI_Galleries/GoToPreset3';
import PtzCtrl from 'components/Software/Home_Automation/CGI_Galleries/PtzCtrl';
import PtzCtrlTour from 'components/Software/Home_Automation/CGI_Galleries/PtzCtrlTour';
import SetSchedule from 'components/Software/Home_Automation/CGI_Galleries/SetSchedule';
import SetScheduleBak from 'components/Software/Home_Automation/CGI_Galleries/SetScheduleBak';
import SetMdAlarm from 'components/Software/Home_Automation/CGI_Galleries/SetMdAlarm';
import SetMdAttr4 from 'components/Software/Home_Automation/CGI_Galleries/SetMdAttr4';
import SetPirAttr from 'components/Software/Home_Automation/CGI_Galleries/SetPirAttr';
import SetIoAttr from 'components/Software/Home_Automation/CGI_Galleries/SetIoAttr';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import NavButtons from 'components/Software/Home_Automation/NavButtons';
import ForumBox from 'components/Software/Home_Automation/Homematic/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Homematic Home Automation",
  "path": "/Software/Home_Automation/Homematic/",
  "dateChanged": "2019-06-03",
  "author": "Mike Polinowski",
  "excerpt": "Configure your Homematic or Homematic IP to control your INSTAR IP camera.",
  "social": "/images/Search/AU_SearchThumb_Homematic.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Homematic_white.webp",
  "chapter": "Software",
  "category": "smarthome",
  "type": "Homematic"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <SEOHelmet title='Homematic Home Automation' dateChanged='2019-06-03' author='Mike Polinowski' tag='INSTAR IP Camera' description='Configure your Homematic or Homematic IP to control your INSTAR IP camera.' image='/images/Search/AU_SearchThumb_Homematic.png' twitter='/images/Search/AU_SearchThumb_Homematic.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Home_Automation/Homematic/' locationFR='/fr/Home_Automation/Homematic/' crumbLabel="Homematic" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "home-automation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#home-automation",
        "aria-label": "home automation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Home Automation`}</h2>
    <h3 {...{
      "id": "homematic-home-automation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#homematic-home-automation",
        "aria-label": "homematic home automation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Homematic Home Automation`}</h3>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#home-automation"
        }}>{`Home Automation`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#homematic-home-automation"
            }}>{`Homematic Home Automation`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#introduction"
        }}>{`Introduction`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#how-does-it-work"
        }}>{`How does it work`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-external-sensors"
            }}>{`Use external Sensors`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#activate-nightvision-only-when-motion-is-detected"
            }}>{`Activate Nightvision only when Motion is detected`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#schedule-different-positions"
            }}>{`Schedule different Positions`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-different-detection-areas-for-each-position"
            }}>{`Use different Detection Areas for each Position`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-different-sensitivities-for-day--night"
            }}>{`Use different Sensitivities for Day & Night`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#turn-off-all-cameras-when-you-arrive-home"
            }}>{`Turn off all Cameras when you arrive home`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-external-sensors-to-turn-your-camera-when-motion-is-detected"
            }}>{`Use external Sensors to turn your Camera when Motion is detected`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-external-sensors-to-trigger-alarm-recordings"
            }}>{`Use external Sensors to trigger Alarm Recordings`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#interconnect-your-cameras"
            }}>{`Interconnect your Cameras`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#scan-for-noise-sources"
            }}>{`Scan for Noise Sources`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#manually-deactivate-all-alarm-functions-for-all-your-cameras"
            }}>{`Manually deactivate all Alarm functions for all your Cameras`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#switch-between-pir-and-software-motion-detection"
            }}>{`Switch between PIR and Software Motion Detection`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.homematic.com"
      }}>{`Homematic`}</a>{` is the smart home system manufactured by `}<a parentName="p" {...{
        "href": "http://www.eq-3.de/"
      }}>{`eQ-3`}</a>{`. It enables comprehensive control of various functions – from simple to complex scenarios in houses or flats. Besides increasing the level of comfort and security in homes, another of the system’s main benefits is the way in which it considerably reduces energy costs.`}</p>
    <p>{`The wide portfolio of more than 80 devices includes products for light, shutter and radiator control, with the range being rounded off by hazard alarms, safety sensors and products for measuring weather data. All of which can be configured to interact with your `}<strong parentName="p">{`INSTAR IP camera`}</strong>{`.`}</p>
    <p>{`Homematic can be set up using the browser-based user interface of the Homematic CCU2 Central Control Unit. The system can be controlled from within the local network as well as via the Internet. The software offers various configuration and application possibilities, so that there are no limits to creativity. If you don't want to use the proprietary hardware, just install the Homematic software on a Raspberry Pi - everything you need is available on `}<a parentName="p" {...{
        "href": "https://github.com/hobbyquaker/awesome-homematic#ccu-alternatives"
      }}>{`Github`}</a>{`.`}</p>
    <p>{`In the following paragraphs we want to show you how to connect external wireless sensors through the Homematic system with your INSTAR IP camera, set up time schedules for your surveillance and optimize your camera operation.`}</p>
    <p>{`If you are looking for a deep dive into setting up your Homematic automation with an INSTAR IP camera, switch to our `}<strong parentName="p">{`FULL GUIDE`}</strong>{` instead, where we show you how to add your camera using both the `}<a parentName="p" {...{
        "href": "/en/Software/Other_Platforms/Homematic_IP/"
      }}>{`CuX Daemon`}</a>{` and the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Homematic_CCU3_and_RedMatic/"
      }}>{`RedMatic Node-RED Plugin`}</a>{`.`}</p>
    <h2 {...{
      "id": "how-does-it-work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-does-it-work",
        "aria-label": "how does it work permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How does it work`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "1-use-external-sensors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#1-use-external-sensors",
        "aria-label": "1 use external sensors permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Use external Sensors`}</h3>
    <p>{`Triggering an alarm on the camera by an event registered by your home automation system:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/886078e46ff8e1a6dd0d06e94ff90032/29007/homematic-EN_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACAklEQVQoz22SzW7TQBSF/XI8DE9ChdizhgWbSkiwgkVVBDT9UWnSpAGjJI0b27Fjx7/x2DO24w/FqaOq4kqfzszVnDN3pNGapmHHropCopQCun3BOggJw4AkiQnCiCzL6CqJYxzHwfd9pJRtT+NgB8+P8cINKRBV4NewVCAyQbbJWBZbzI3CDFPMOEM1EEUxqqzocrQqWdCSLijCOcV6hgrnqNBABfftOloZhO6cInwgcXQCa0y01CkTkzyYUcYPdDmadPt0KPeGMpigQosydpBrg2o1wFvecm+NqFZ9Sm9I5fcpnQuk3UOtbnmaoSlvyJ5R26g2Ntsio1GCSkRsgzEL+w7DuqP2hsjVgCKYUo/fUt4cIdd6G9rlHALlo4rFFen0jM39OenkJ7V7w++HEfZy3E5LonP9/RP6x5cwOqLwnwXubpTunnZ86xI5O91jXbQTGZNbzn58IzGvIRhy8vk9X968gLtX5Kvx43MH7VlN2D1arB65c4UYHCPOP+zpH5O7l3j2lD+6TmJegXHKr6/v+HvymmZ+TGZfkHcZdg+t3KYcqPdaNALZCGoEskoJAp80TShUSoPA8QzyKqNCHjwdmqpiOspWI+xcsS42mKaOtZy2uJ7B0p1h2hMKFVLVu/8X8tS/Q6u3Oc8JlUJUEiEiMhGRFwmFTFvdoaqM//l2/AN0OzdybXEIIQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/886078e46ff8e1a6dd0d06e94ff90032/e4706/homematic-EN_01.avif 230w", "/en/static/886078e46ff8e1a6dd0d06e94ff90032/d1af7/homematic-EN_01.avif 460w", "/en/static/886078e46ff8e1a6dd0d06e94ff90032/7f308/homematic-EN_01.avif 920w", "/en/static/886078e46ff8e1a6dd0d06e94ff90032/e1c99/homematic-EN_01.avif 1380w", "/en/static/886078e46ff8e1a6dd0d06e94ff90032/2babf/homematic-EN_01.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/886078e46ff8e1a6dd0d06e94ff90032/a0b58/homematic-EN_01.webp 230w", "/en/static/886078e46ff8e1a6dd0d06e94ff90032/bc10c/homematic-EN_01.webp 460w", "/en/static/886078e46ff8e1a6dd0d06e94ff90032/966d8/homematic-EN_01.webp 920w", "/en/static/886078e46ff8e1a6dd0d06e94ff90032/445df/homematic-EN_01.webp 1380w", "/en/static/886078e46ff8e1a6dd0d06e94ff90032/fad48/homematic-EN_01.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/886078e46ff8e1a6dd0d06e94ff90032/81c8e/homematic-EN_01.png 230w", "/en/static/886078e46ff8e1a6dd0d06e94ff90032/08a84/homematic-EN_01.png 460w", "/en/static/886078e46ff8e1a6dd0d06e94ff90032/c0255/homematic-EN_01.png 920w", "/en/static/886078e46ff8e1a6dd0d06e94ff90032/b1001/homematic-EN_01.png 1380w", "/en/static/886078e46ff8e1a6dd0d06e94ff90032/29007/homematic-EN_01.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/886078e46ff8e1a6dd0d06e94ff90032/c0255/homematic-EN_01.png",
              "alt": "Homematic",
              "title": "Homematic",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=pushhostalarm&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <PushHostAlarm mdxType="PushHostAlarm" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "2-activate-nightvision-only-when-motion-is-detected",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2-activate-nightvision-only-when-motion-is-detected",
        "aria-label": "2 activate nightvision only when motion is detected permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Activate Nightvision only when Motion is detected`}</h3>
    <p>{`Activate your cameras night vision according to a schedule or triggered by an external sensor integrated in home automation system:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1bd9c1ee67f3688f0262858ba9e75914/29007/homematic-EN_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACvUlEQVQoz22RW0gUcRTG5y0Ieu6pKFrb2N1sZ+Y/zrozbu64Y67mtqmLaRReKmm7ULmkq4ZBUHS3iKArREUEKYSWZWm6XSzoCkXUSy+hT1FUVKb2q13qpTrwcQ6Hj+985xxp6uAYoWc/CD2ZIPB4HMe9MWbfHWPm3e9MH/iEdPkdM259xDn8HfnhJNaTCYxH4xQ+ncBM52eTyMNfmHrtPVLPB6Qp+3uZebgXx4khHCdTuE6n0M7cYe7xASquv8boH8Xue4P71BDT9naTdWyAOccHcZ8cxHVqiFlHb2B0PSf35ijOrldI9curaKivQ3izkT0ulPluPM4sCq18hlMpBvv66em8RGN8DevqawmHLEIL8yjIMzKwF+bRf/UK92+l6Dx3FuntyAjpsEuX4CooQdglzM7NZ9e+g3z5Ns75C5do397Oi5cvM7x1mxtx2RF8JUtxBsNsaW7l4+evdPfepCnZjBSPx0kkEuT6/Si6D81v4pJV4us3krr9gI7DR1hUEGRbWxuNiQSRSATbtikOh8kPhVhRv4q+6wNcOH8RJduD5PF4cLvdCFVFEyIDVZYJBALs2LWHaLiYmmiEsrIyHA4HqqoiNA1FCHRVpVQIOpJtrKxeTkj2IuXk5KDrOpqmZYhp/Km9zrmUGj4OHNxN5bJlyLJMmm8IQdTno1bTSHi9FGVlYeuCluQWJO23wN9QVBU7YNLctpW9HXuIxWJkL1iAz+cjWlRELBCg1jCo1XWsHI2mlgQt7cl/Bf84TLspLC5m6+py9u9spbKqmnlOJ6WRCB2HDqHIMmG/nxJdx7QKaF5bReumuv84TN8x7VBRsKx8VhTKJDfUEKuszAypqKigoaGBYDCIaZpoqsDMM6lZnEtdWfD/K4vfoppQ8XjcRKNLKC8vz/Qty8Lv92MFgxiGkekJoaIoMsqvp/wEy6XPyNMT/9EAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1bd9c1ee67f3688f0262858ba9e75914/e4706/homematic-EN_02.avif 230w", "/en/static/1bd9c1ee67f3688f0262858ba9e75914/d1af7/homematic-EN_02.avif 460w", "/en/static/1bd9c1ee67f3688f0262858ba9e75914/7f308/homematic-EN_02.avif 920w", "/en/static/1bd9c1ee67f3688f0262858ba9e75914/e1c99/homematic-EN_02.avif 1380w", "/en/static/1bd9c1ee67f3688f0262858ba9e75914/2babf/homematic-EN_02.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1bd9c1ee67f3688f0262858ba9e75914/a0b58/homematic-EN_02.webp 230w", "/en/static/1bd9c1ee67f3688f0262858ba9e75914/bc10c/homematic-EN_02.webp 460w", "/en/static/1bd9c1ee67f3688f0262858ba9e75914/966d8/homematic-EN_02.webp 920w", "/en/static/1bd9c1ee67f3688f0262858ba9e75914/445df/homematic-EN_02.webp 1380w", "/en/static/1bd9c1ee67f3688f0262858ba9e75914/fad48/homematic-EN_02.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1bd9c1ee67f3688f0262858ba9e75914/81c8e/homematic-EN_02.png 230w", "/en/static/1bd9c1ee67f3688f0262858ba9e75914/08a84/homematic-EN_02.png 460w", "/en/static/1bd9c1ee67f3688f0262858ba9e75914/c0255/homematic-EN_02.png 920w", "/en/static/1bd9c1ee67f3688f0262858ba9e75914/b1001/homematic-EN_02.png 1380w", "/en/static/1bd9c1ee67f3688f0262858ba9e75914/29007/homematic-EN_02.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1bd9c1ee67f3688f0262858ba9e75914/c0255/homematic-EN_02.png",
              "alt": "Homematic",
              "title": "Homematic",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=setinfrared&-infraredstat=auto&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=setinfrared&-infraredstat=close&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <SetInfrared mdxType="SetInfrared" />
    <p>{`The activation of the night vision in case of an alarm is of course only possible if the camera has an internal PIR sensor for motion detection, or if you use the alarm signal of an external sensor in home automation to "focus" the camera. The advantage - the camera is inconspicuous at night and the IR light attracts no insects / spiders. And the power consumption is of course also pressed.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "3-schedule-different-positions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#3-schedule-different-positions",
        "aria-label": "3 schedule different positions permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Schedule different Positions`}</h3>
    <p>{`Scheduled day and night position - guard the front door when I'm not at home but I turn the camera to my carport when I'm back from work:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9eb12442e1d3edef73fa07d6763f7229/29007/homematic-EN_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC7ElEQVQoz1WR329TBRTH71+gD7xoJAYyg0Bub3t773ov22ADNpYw2OzmDyY6a9utMmXLgKV2jllWBzH+ivHJmEg00WeflxgTx6wDxhQGBbZJEEgYt9WubOtt74+P4eKDnuSTk/PyzfmcI3yabCb7/Wbee6uTj8eGOT2c4MPUUcaHYgy8up9EZxMj/VEyQ+18NvQEzUEfySNR+g6F6ek8SOTlMPt37UB89in8m59BGHmzlXRUYvClJlKRdkbiXRzrbuH4oT0MH25h8MV6MgOvkYyF6WvdQJu8lVcaVToaVFoUkWZ5GwfrFcSnn8S/cQPCwuVZcpcusvDbDDcuTXN17jzzF6fJzU5xfXaKq+d/4lZujkTkDQJbttIYkpGfr+GLTz7i2Dtvs71mE7pfZOJkivS7JxD4T7nAWrlCcbXMyr/z6rqJadnE4nF8kkQgqHCgvYNiaZWfp39F2rWXht17KK6U+P3KFYSKaWJZVRzbplR6SHbmArnrN8F1MMvr2Lj8vbxMpKcHSZIIyjJtbW1MncsyOfkj9XV1qIrCC+EwnR0dCNVqBcexvUDbtllYXGL5zl2oVr2tb8/NMfXdN8R74/hEEV3XCIVCjL2f5ujAILIsU6tpbKt5jgN1OkLVsnFcHuM4j90ty2sLv2SZ/PJzCsV7ROO9iKIPfUcdIU1DC4UIBAKotbWooo9wYwPpdArBXCtRNhaprNz3Am3Lwq5WcVyXpdkZjLX7FM2/6ItFaFK3sG+njKqqaJqOrusEFYVGLUQydYKxzEmE1QdLWOsrlPO3sMw1HMf11G3L9p5SyH5L/s48I8cT9HR3kXg9zE5dQa3V0DQNRQmye18rqSPdnEr2I5glA9d1sNaLOFYF11O3PeWCcY8LXw2Sm/6Bgf44sWiU3shhlICIJPnx+/34fI/OoNPdLBPt2ovwwDDI5/MexiMMw6NQKHD3z9tcuzbPH4s3+frsWUZHRxkfzzAxMfF/PsgwfirNmTOn+QfsglgU+9QrEAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9eb12442e1d3edef73fa07d6763f7229/e4706/homematic-EN_03.avif 230w", "/en/static/9eb12442e1d3edef73fa07d6763f7229/d1af7/homematic-EN_03.avif 460w", "/en/static/9eb12442e1d3edef73fa07d6763f7229/7f308/homematic-EN_03.avif 920w", "/en/static/9eb12442e1d3edef73fa07d6763f7229/e1c99/homematic-EN_03.avif 1380w", "/en/static/9eb12442e1d3edef73fa07d6763f7229/2babf/homematic-EN_03.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9eb12442e1d3edef73fa07d6763f7229/a0b58/homematic-EN_03.webp 230w", "/en/static/9eb12442e1d3edef73fa07d6763f7229/bc10c/homematic-EN_03.webp 460w", "/en/static/9eb12442e1d3edef73fa07d6763f7229/966d8/homematic-EN_03.webp 920w", "/en/static/9eb12442e1d3edef73fa07d6763f7229/445df/homematic-EN_03.webp 1380w", "/en/static/9eb12442e1d3edef73fa07d6763f7229/fad48/homematic-EN_03.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9eb12442e1d3edef73fa07d6763f7229/81c8e/homematic-EN_03.png 230w", "/en/static/9eb12442e1d3edef73fa07d6763f7229/08a84/homematic-EN_03.png 460w", "/en/static/9eb12442e1d3edef73fa07d6763f7229/c0255/homematic-EN_03.png 920w", "/en/static/9eb12442e1d3edef73fa07d6763f7229/b1001/homematic-EN_03.png 1380w", "/en/static/9eb12442e1d3edef73fa07d6763f7229/29007/homematic-EN_03.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9eb12442e1d3edef73fa07d6763f7229/c0255/homematic-EN_03.png",
              "alt": "Homematic",
              "title": "Homematic",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=preset&-act=goto&-number=0&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=preset&-act=goto&-number=1&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <GoToPreset mdxType="GoToPreset" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "4-use-different-detection-areas-for-each-position",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#4-use-different-detection-areas-for-each-position",
        "aria-label": "4 use different detection areas for each position permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Use different Detection Areas for each Position`}</h3>
    <p>{`In combination with the scheduled day and night position (see above) you can also activate different motion detection areas - in the example the areas 1 & 2 are activated during the day and the areas 3 & 4 at night:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/92346e686ade8fc17eff2b3a618f1252/29007/homematic-EN_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAADDElEQVQoz1WPz0/bBQBHv/+A8bLEixfcUmfXlv6GrqUM7BjDspZ1kgBb6dpiRxmQUdh0JUOQBIaAGo3JYuL0oNHEZN0Jt8yxlFGgYS1j0DLaOQ0Z3WpRJkuhgDwj8aDv8sm7vY8w2FHKxLd5dDbaGPC3cfm9s/Sd99J3oYmLZ2posBTR1eqhu+UtPjr3ModVMrx1NpodtZiLD2JUHKBYKaFEnY8h/w2Ed+tNdDvzaT5u5PypCjo91XTUHcFXfYj2WhOtNj2XfS7a64/hKd9DuWwf1UYV1iI1FToFFVop5VopJTIRBnEeQmL2HvHINImZSR5GQsxHwkTDd4mEx5ifvkssPMqj+Wk8jnrkov0UaxQoXn+Nz4YHafWeYb9oHwVyKZc6ztF1wYfAf9gBsus5VtbWyf3rL7IbbGxt43K7kebLkCuVHLNW8Xwty53gOHKdAX2RkfRvGR4mEgjZbJZcLsfm5iarq88ZnwgTiy3Azl/k1tfZZoc/nj3DYbcjk8lQKhSYzZWMjU/x441b6HU61Go1FmsVxy3W/xf+Q2p5mbWVDGxv7/qv0Shj33yNu8GNVCKhsLAArVZL16Uumpq8yGVSVCoF4r15WA2FCMGpKKG5JJMzcaaiMaZnF4nej7OxtUVycpKbVz5hZfUJTncDEomUQt1BNBoNGn0JKn0pBcVl6EvKMZuOcratHSHw3TVu+Xu4/n2AiQcLzMQfExi5TSKRZOnBDOm1FKsbv/OOy8EhtYgygwKlUkmNvQHrCQdv17oxmU9i97QzOhFBWBgJMK8X01fdg/10mNhshnR6iVgsvns5E/qKzNIcF30e7DU2PPYqDGolRosBUfcriF2v0tz4Eh/0GEml0giha5+TbHPyacsX1NtD/DQ6Rmr5EfHYIi/+zBC+0kx8PECL143L6cR9ug7lATHOtpOcumnD90MjN0ZOcPtOJ788XkY4WnYYS5WFSkslFquZisojmErfJBgMkkk/ZW5ulp+Ti3x59Sp+v5/e3t7dfb/Tz1DfEMMDw/T3DzE4+DEf9g/wN1DrQ0o+DdAyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/92346e686ade8fc17eff2b3a618f1252/e4706/homematic-EN_04.avif 230w", "/en/static/92346e686ade8fc17eff2b3a618f1252/d1af7/homematic-EN_04.avif 460w", "/en/static/92346e686ade8fc17eff2b3a618f1252/7f308/homematic-EN_04.avif 920w", "/en/static/92346e686ade8fc17eff2b3a618f1252/e1c99/homematic-EN_04.avif 1380w", "/en/static/92346e686ade8fc17eff2b3a618f1252/2babf/homematic-EN_04.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/92346e686ade8fc17eff2b3a618f1252/a0b58/homematic-EN_04.webp 230w", "/en/static/92346e686ade8fc17eff2b3a618f1252/bc10c/homematic-EN_04.webp 460w", "/en/static/92346e686ade8fc17eff2b3a618f1252/966d8/homematic-EN_04.webp 920w", "/en/static/92346e686ade8fc17eff2b3a618f1252/445df/homematic-EN_04.webp 1380w", "/en/static/92346e686ade8fc17eff2b3a618f1252/fad48/homematic-EN_04.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/92346e686ade8fc17eff2b3a618f1252/81c8e/homematic-EN_04.png 230w", "/en/static/92346e686ade8fc17eff2b3a618f1252/08a84/homematic-EN_04.png 460w", "/en/static/92346e686ade8fc17eff2b3a618f1252/c0255/homematic-EN_04.png 920w", "/en/static/92346e686ade8fc17eff2b3a618f1252/b1001/homematic-EN_04.png 1380w", "/en/static/92346e686ade8fc17eff2b3a618f1252/29007/homematic-EN_04.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/92346e686ade8fc17eff2b3a618f1252/c0255/homematic-EN_04.png",
              "alt": "Homematic",
              "title": "Homematic",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=setmdattr&-name=1&-enable=1&cmd=setmdattr&-name=2&-enable=1&cmd=setmdattr&-name=3&-enable=0&cmd=setmdattr&-name=4&-enable=0&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=setmdattr&-name=1&-enable=0&cmd=setmdattr&-name=2&-enable=0& cmd=setmdattr&-name=3&-enable=1&cmd=setmdattr&-name=4&-enable=1&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <SetMdAttr mdxType="SetMdAttr" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "5-use-different-sensitivities-for-day--night",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#5-use-different-sensitivities-for-day--night",
        "aria-label": "5 use different sensitivities for day  night permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`5. Use different Sensitivities for Day & Night`}</h3>
    <p>{`Since there are often different sensitivities needed for the motion detection during day and night conditions, you can also adjust these - in the example to 75% during the day and 25% at night:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dc3f89dc316858dad848ddba49ed8985/29007/homematic-EN_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAADCElEQVQozzWP609bBRjGzz/hR4MXsgC2tSjSdVWoipuDD2MSN93G1pYOBjqyuSXTOZg0MA14iTg76xLqZBgzGIlx6wScgkBXwDAvW2BFcISxqAfa0wvr6bn0Z+j0SZ68z/Pll+cVDJeHODu6k5yBRSzjSTaNS1jHYhhGJDaNSeSPSBQMRak/UUfHKSO2HS72H2/hteZW6k94aHznFNWH36TcWY9x60sIz44u4QgGsQclqn6OUzERo+xaDNu4xAuhGPnDEuVBieqDDby8p5jiiiq27nZQttvFc9W1PL3TQemrLjbvq8O8ZRuComikdNB1nXuqzmJcZjkSQ0ymWElpiLJGXFZwOWvYkGdi40YLj+bmctTzLlM3ZvgxNJm9w9M32bX/AEI6nSajqeiaRlrT+SuRQl5Lsq6EDFO3k8QUFXeNC6PJiM1mY0NePp1d5/hfWgYWozruQ0cQNF1HA/RMhrW0xt34PVQ5RVpOMTR0lTtRmYh8H1iQn4el+EkeejiHM1+eJzw7Q/jW7H/QDK7GNxAGro7g9/dxoTfAxe9H+W35H2IpmduixHcTM0QVjdWUgtvlxFhYzDOlFTxmtvGer5vhXxcI3ZznjrhCePlv9hx4HeGn0CR3P/6A4OEj9AfmuPVnmqWVKNd++R0dkGSVtKLicjqpqKxix6EaXmmooXz7i5w97+diYJBCq42SLeXYny9DGBy5wnTtdrqLSikyf8PbxwIk4ktMTUxlX8loGqqq4Ha6MVkKyPU+wFNvPUjLcYGBwWZ6evrJfSSHoicKKS0pQejr7ab30/e54PXi813m3Fdfc+nbfsbHglmgqqoo6vpCFwcb6/AOdPLFpc+5EviQ6es/4P3kDKbHTVgsFux2O8LC/AKT09cZmwjR19tDODzLH3PzJBKJ+wszmSzU4XBQua0S5y4npz86TUdHJ7W1Dezbuxez2YzBYMBqtSJEIhHWkklWRJG5uXnWezwRZ3V1FVEUs17Pfr+fpqYmWjwtdPm78Pk+4+TJZjweD21tbbS2ttLe3s6/vPqBOBKt6pQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc3f89dc316858dad848ddba49ed8985/e4706/homematic-EN_05.avif 230w", "/en/static/dc3f89dc316858dad848ddba49ed8985/d1af7/homematic-EN_05.avif 460w", "/en/static/dc3f89dc316858dad848ddba49ed8985/7f308/homematic-EN_05.avif 920w", "/en/static/dc3f89dc316858dad848ddba49ed8985/e1c99/homematic-EN_05.avif 1380w", "/en/static/dc3f89dc316858dad848ddba49ed8985/2babf/homematic-EN_05.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dc3f89dc316858dad848ddba49ed8985/a0b58/homematic-EN_05.webp 230w", "/en/static/dc3f89dc316858dad848ddba49ed8985/bc10c/homematic-EN_05.webp 460w", "/en/static/dc3f89dc316858dad848ddba49ed8985/966d8/homematic-EN_05.webp 920w", "/en/static/dc3f89dc316858dad848ddba49ed8985/445df/homematic-EN_05.webp 1380w", "/en/static/dc3f89dc316858dad848ddba49ed8985/fad48/homematic-EN_05.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc3f89dc316858dad848ddba49ed8985/81c8e/homematic-EN_05.png 230w", "/en/static/dc3f89dc316858dad848ddba49ed8985/08a84/homematic-EN_05.png 460w", "/en/static/dc3f89dc316858dad848ddba49ed8985/c0255/homematic-EN_05.png 920w", "/en/static/dc3f89dc316858dad848ddba49ed8985/b1001/homematic-EN_05.png 1380w", "/en/static/dc3f89dc316858dad848ddba49ed8985/29007/homematic-EN_05.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dc3f89dc316858dad848ddba49ed8985/c0255/homematic-EN_05.png",
              "alt": "Homematic",
              "title": "Homematic",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=setmdattr&-name=1&-s=75&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=setmdattr&-name=1&-s=25&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <SetMdAttr2 mdxType="SetMdAttr2" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "6-turn-off-all-cameras-when-you-arrive-home",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#6-turn-off-all-cameras-when-you-arrive-home",
        "aria-label": "6 turn off all cameras when you arrive home permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`6. Turn off all Cameras when you arrive home`}</h3>
    <p>{`Either schedule or manually disable all alarm detection areas and the PIR sensor, if you do not want to trigger an alarm:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cbfad6feafe6d2153379351d4b128bc1/29007/homematic-EN_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACOUlEQVQoz3WSy04UQRSG+xV4CE1MiMjCuDIxbl0ZQZCY6OOgL6FuXBgjUTe6AkaUSxhERAzoMDMtc6Fn+lLd0z3dVdX9ma4Z0MRYyV+nzum/zvnPqbas130urfhcqQRMrQZMVwIuLPtcWwuYqkRcXP7BdGWDy6tNJldCJld9pioBVz8ETLxzmXjvYb3tYy31sJb6WDfXBbPVkIWqYG47ZGEnZGY7ZK5aIubOdoP7O1Xu7rSZ+ZwyVxXc3gqY3024txvzcDfi1qbg+kfBjU8Ci/GSQAoo/rOkIuo79Fq/DILTDjoZ/EOzZAENkfDi5SuePX1C9bBGAhRFgVSavuvStJvYJye4no8II6I4NrbV7tBo2nQdhzhN0WXCcvtut3gwP8ujxUVanS6eBi0zGo0GjuOQZdm5giiKGEThua+1xvd96vW6+WblQCfO+FLdoNM4wpUFscqhyBHhn4sUBb1U48mCQBU4aW5iBkAYhkgpscqAKiDIoZNIRKYNIc9zAiFGuYDWMOdokPMt1OwJxWGkTawY1xNCoJQaPYoXDtnab2PHGqFGFJ3nDAYDbNvm53GdSIM3lERSE6qCUEOv7OzrPnEcm3ZHCoHNg1MeP98hVTl6XLJUWBJbrTbdbndURGYUWqOlHHGUpFY7NjMuixuFe3XB+oHDm7UaaabM7Ep1ZcJer2fgeR5BEJi2Sr/d7piZeb6P67rmUcp4mdhKUkU8zEhSaX6TfJysRJqmRmWSJMae4W//7Fza8s5vs9ElpVPO+6EAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cbfad6feafe6d2153379351d4b128bc1/e4706/homematic-EN_06.avif 230w", "/en/static/cbfad6feafe6d2153379351d4b128bc1/d1af7/homematic-EN_06.avif 460w", "/en/static/cbfad6feafe6d2153379351d4b128bc1/7f308/homematic-EN_06.avif 920w", "/en/static/cbfad6feafe6d2153379351d4b128bc1/e1c99/homematic-EN_06.avif 1380w", "/en/static/cbfad6feafe6d2153379351d4b128bc1/2babf/homematic-EN_06.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cbfad6feafe6d2153379351d4b128bc1/a0b58/homematic-EN_06.webp 230w", "/en/static/cbfad6feafe6d2153379351d4b128bc1/bc10c/homematic-EN_06.webp 460w", "/en/static/cbfad6feafe6d2153379351d4b128bc1/966d8/homematic-EN_06.webp 920w", "/en/static/cbfad6feafe6d2153379351d4b128bc1/445df/homematic-EN_06.webp 1380w", "/en/static/cbfad6feafe6d2153379351d4b128bc1/fad48/homematic-EN_06.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cbfad6feafe6d2153379351d4b128bc1/81c8e/homematic-EN_06.png 230w", "/en/static/cbfad6feafe6d2153379351d4b128bc1/08a84/homematic-EN_06.png 460w", "/en/static/cbfad6feafe6d2153379351d4b128bc1/c0255/homematic-EN_06.png 920w", "/en/static/cbfad6feafe6d2153379351d4b128bc1/b1001/homematic-EN_06.png 1380w", "/en/static/cbfad6feafe6d2153379351d4b128bc1/29007/homematic-EN_06.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cbfad6feafe6d2153379351d4b128bc1/c0255/homematic-EN_06.png",
              "alt": "Homematic",
              "title": "Homematic",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=setmdattr&-name=1&-enable=0&cmd=setmdattr&-name=2&-enable=0&cmd=setmdattr&-name=3&-enable=0&cmd=setmdattr&-name=4&-enable=0?cmd=setpirattr&-pir_enable=0&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=setmdattr&-name=1&-enable=1&cmd=setmdattr&-name=2&-enable=1&cmd=setmdattr&-name=3&-enable=1&cmd=setmdattr&-name=4&-enable=1?cmd=setpirattr&-pir_enable=1&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <SetMdAttr3 mdxType="SetMdAttr3" />
    <p>{`Please note for the models `}<strong parentName="p">{`IN-6014 HD`}</strong>{` and `}<strong parentName="p">{`IN-9020 Full HD`}</strong>{`, instead of the PIR sensor - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`setpirattr&-pir_enable=1`}</code>{` - the alarm input must be switched: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`cmd=setioattr&-io_enable=1`}</code>{`. The same applies to cameras where an external PIR sensor is attached to the alarm input.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "7-use-external-sensors-to-turn-your-camera-when-motion-is-detected",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#7-use-external-sensors-to-turn-your-camera-when-motion-is-detected",
        "aria-label": "7 use external sensors to turn your camera when motion is detected permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`7. Use external Sensors to turn your Camera when Motion is detected`}</h3>
    <p>{`Let your camera move between positions depending on system variables in home automation system. For example - when the door sensor is tripped, look to the door, when the window sensor is triggered turn towards the window and when the external motion sensor detects a person, turn to the kitchen door:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/df45d9f2de76eedf57ff686f069402d8/29007/homematic-EN_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACiElEQVQozz2LS28bVRTHZ8FHYMOmC0ShLBASEstWPIrEggUbdogvgATfA3XVbWHBCnUDbdVSKaiOm2I7cV3FqR0nOEkdO5nYc+fOnced5x17fijjqkf6n3P+L+vtDcW1TZ+rDZ+Pnwb1//5r/slWwDv/KK488fn0WcC7DZ/3Gj4fPQ3qzIdNnw8219pbjzysexLr807IVzuam92YmzuaLy75dsRnnZAvay/ih13N97uaG+2w9teI1ndH800v5uudiOutAItqiQl9zo//gzwFKmDFm6mWsCqhNK+9N0a9/fk5iZJrXpVY9lxyrjQnnmasUjw/wA01p2lFYMCRijhNWQiF7YZEFUySFcIPUaFmJDVTpZFBiApCrLi7jWk2gCWUKUkckqcRVZmxKjOKTGPyBB0q0khRFTErk5DGYQ1WBVSGMk/IU42VNx/A7VuUey2MHOEvhqRiSCFekouXGNEncw/J1RTjTSi8U4w7QjtDtLNf54zYW+ecIZZ53kTf/5Og1QQxwp4PiRZ9jDykGN+lbP1MZm+TRy5l5FEmAUs1RlzsIRcDVnKIfNUhffEL2fQJVnbYQvT+xtlvUDp7HI1fIGe7GP+EYv8Oy41vye1t4sUhyWmHeNrFLPqcT/pMj3qgx2w+/J3xH99R2Q2seP8Ref8vyoOHiOMt2s97DA4GJJM2xeBXimc/ktodkvEWWe8u2eAe0azN8GDEv90u+axN7/FvnD3+CTPdwMrkEbl6RaGOSPWMC6UQocLoKSY+o8hssmROpiYU6oRCHZPpKXPl4oQBZTwj8k4o0jOy+AzL8RxcJRBK4HoLpGsj5QXCc9aQC6QnkL5EKKfOXerSvUBKG3HZqbsOjjvnfxplDoU5yZsKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df45d9f2de76eedf57ff686f069402d8/e4706/homematic-EN_07.avif 230w", "/en/static/df45d9f2de76eedf57ff686f069402d8/d1af7/homematic-EN_07.avif 460w", "/en/static/df45d9f2de76eedf57ff686f069402d8/7f308/homematic-EN_07.avif 920w", "/en/static/df45d9f2de76eedf57ff686f069402d8/e1c99/homematic-EN_07.avif 1380w", "/en/static/df45d9f2de76eedf57ff686f069402d8/2babf/homematic-EN_07.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/df45d9f2de76eedf57ff686f069402d8/a0b58/homematic-EN_07.webp 230w", "/en/static/df45d9f2de76eedf57ff686f069402d8/bc10c/homematic-EN_07.webp 460w", "/en/static/df45d9f2de76eedf57ff686f069402d8/966d8/homematic-EN_07.webp 920w", "/en/static/df45d9f2de76eedf57ff686f069402d8/445df/homematic-EN_07.webp 1380w", "/en/static/df45d9f2de76eedf57ff686f069402d8/fad48/homematic-EN_07.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df45d9f2de76eedf57ff686f069402d8/81c8e/homematic-EN_07.png 230w", "/en/static/df45d9f2de76eedf57ff686f069402d8/08a84/homematic-EN_07.png 460w", "/en/static/df45d9f2de76eedf57ff686f069402d8/c0255/homematic-EN_07.png 920w", "/en/static/df45d9f2de76eedf57ff686f069402d8/b1001/homematic-EN_07.png 1380w", "/en/static/df45d9f2de76eedf57ff686f069402d8/29007/homematic-EN_07.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/df45d9f2de76eedf57ff686f069402d8/c0255/homematic-EN_07.png",
              "alt": "Homematic",
              "title": "Homematic",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=preset&-act=goto&-number=0&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=preset&-act=goto&-number=1&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=preset&-act=goto&-number=2&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <GoToPreset2 mdxType="GoToPreset2" />
    <p>{`etc.`}</p>
    <p>{`This can be combined well with the parking position in the camera - the camera then automatically returns to the standard position after x minutes.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "8-use-external-sensors-to-trigger-alarm-recordings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#8-use-external-sensors-to-trigger-alarm-recordings",
        "aria-label": "8 use external sensors to trigger alarm recordings permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`8. Use external Sensors to trigger Alarm Recordings`}</h3>
    <p>{`When you set up your camera to turn to a position when triggered by an external sensor, you probably want to start an alarm recording on the internal SD card to catch the intruder. This can be done by starting the manual recording through your home automation system and after a delay following up the stop command. Due to adjustable delay, the length of the alarm video is freely selectable (max. 15min):`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ffb830c3f5febbcfccf018e6c822aaab/29007/homematic-EN_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACHklEQVQoz12P2W7TQBSG/XK8DO+BEBJ3fQLEXS8qAVeoIEFLK1oI2VqaxVma2I4dL1nGy3i8fMh2SAVH+vTPnDPn1z9aWZZUVKWUIo4T/pZKU7wgwHVdNkGA53u4vk9R5PU8iWMc28ZxHIQQQIlGLU2FkcQwXSLAV7AvwFCwjSShEIRpziwqsbYRM2+HUAVCRIgwPobQ1HaK2kzJtjPSYEJkD5DuGOnpyPUIFejs1jPc1ZzInxM5A/xlD2/ZJ/YmJN4Y6eu1R+WlSbvNEy2yarCzyYSL2pikTpfYbrOzeyinTbrukrkdstUVqXlZz1PnyUNL1z0a+nUj2xvk8Z5ChuRhgPLuEXYP27ojW3eRTgfpj8nvTlCtF0hv2JgefI6G8qDR4oa9/g0xvWY/vkDZv+p04+U9uduF3ZCfF+8YnD2H/isSd/CvobQ7NLSRVYLFFXL8CTk6b852m8dhi+urS6zRNbhtPr9/w8fXz+DuJbFzf/hup06vxdZ3KqIK5ydR74zo9pTw5pSkd0Zg/qA/GfKlr9P/fQuLS1rnbxl8PaFYfiC0bpvdmhu0JBckeYgqRE1GhCKpqc+FINh4bLZb4mRPSYLtLuqdnPRpr2xUM+OYaZhgmCPM1YSFMcRc6ZiWznzxgGHpdb/Sx+WApTnCcedYK52lMcSwxvX72eMD/saoEqbEWYpMQ1IVIaVoNA2RMiQ53I+9/zjOpEBlMX8A6k4z6TccDm0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ffb830c3f5febbcfccf018e6c822aaab/e4706/homematic-EN_08.avif 230w", "/en/static/ffb830c3f5febbcfccf018e6c822aaab/d1af7/homematic-EN_08.avif 460w", "/en/static/ffb830c3f5febbcfccf018e6c822aaab/7f308/homematic-EN_08.avif 920w", "/en/static/ffb830c3f5febbcfccf018e6c822aaab/e1c99/homematic-EN_08.avif 1380w", "/en/static/ffb830c3f5febbcfccf018e6c822aaab/2babf/homematic-EN_08.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ffb830c3f5febbcfccf018e6c822aaab/a0b58/homematic-EN_08.webp 230w", "/en/static/ffb830c3f5febbcfccf018e6c822aaab/bc10c/homematic-EN_08.webp 460w", "/en/static/ffb830c3f5febbcfccf018e6c822aaab/966d8/homematic-EN_08.webp 920w", "/en/static/ffb830c3f5febbcfccf018e6c822aaab/445df/homematic-EN_08.webp 1380w", "/en/static/ffb830c3f5febbcfccf018e6c822aaab/fad48/homematic-EN_08.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ffb830c3f5febbcfccf018e6c822aaab/81c8e/homematic-EN_08.png 230w", "/en/static/ffb830c3f5febbcfccf018e6c822aaab/08a84/homematic-EN_08.png 460w", "/en/static/ffb830c3f5febbcfccf018e6c822aaab/c0255/homematic-EN_08.png 920w", "/en/static/ffb830c3f5febbcfccf018e6c822aaab/b1001/homematic-EN_08.png 1380w", "/en/static/ffb830c3f5febbcfccf018e6c822aaab/29007/homematic-EN_08.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ffb830c3f5febbcfccf018e6c822aaab/c0255/homematic-EN_08.png",
              "alt": "Homematic",
              "title": "Homematic",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=manualrec&-act=on&-time=60&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=manualrec&-act=off&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <ManualRec mdxType="ManualRec" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "9-interconnect-your-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#9-interconnect-your-cameras",
        "aria-label": "9 interconnect your cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`9. Interconnect your Cameras`}</h3>
    <p>{`Install your cameras so that they always keep an eye on each other by utilizing the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Features/PTZ/"
      }}>{`Park Position`}</a>{` that always returns your camera to a set position after it was moved. This prevents intruders from tampering with your cameras unseen.`}</p>
    <p>{`When one camera detect a motion you can have it contact your home automation system using your camera's `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`Alarmserver Function`}</a>{`. You smarthome can then send a command to other cameras nearby to turn towards the position where the initial camera detected the intruder and start an alarm recording.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4e29cdfb7b1bfe4f1d1a1bb8e5432537/29007/homematic-EN_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACe0lEQVQoz02SWU9TURSF769hUHp7h3PO7S0dLpOVKw4dqK2KYkWkSOLAWEH6IgYlSokP4pDwIuGfGB/0wYBRf81nzmGIDyt7J2eftddee1vvdz9y9OsPTxaXSGYi3m532O50yI2Osbi2zuHhb/b3D/iyf8Dh0R8Wn62RGhims7PD5709RDZi7tFjjn79ZffDJ6zqeJkfP36ysrZO97lzJJNJHMchCAJebr7m67fvzDZnaM5Mm3xjcwtPKhzXxbaTdCVsllafG45adRzLF4Kl2TkmBwYJXdcQadh2gtHRmJnmQyqVCqVSiQfNh8TxJZJ2gkApUxc6DneiAZZn5/CFxBJS4tk2D1yXe2FIIQyRUhoIIejt6aFWr1OtVunt7UEIH3XyPhKGNNJp89e3bYRSWEJIfBlwIUwbpJQ6I1NK4XgelWKR8UqFpOOiVGCUSCGMyovpNLEWkUrhOw5WoASFjE0hk0RKgbZAR/1BFyW6upgeL1O9fp3Q72MsZ5NNeWghuqlpHAQ4fX3EgcS6mPO4e7NiUMh5eL48JlQKcf48pSjDm+1X1KplSiMBjbuT1C/nUOKYVNf5iQRXMyGt1lOsoYw42eIMOvf8k1Fdl0I+R+v5MludLarlIjfKYzxZWKFWjJG+g5TKTJSVguXWPK1269hDfSYa2httuM4vjIzwbneXhcY1NtbnWV1v02636enuxvN8M6r22vc8soNDrDTrrD5uYJnxTrb2/3aLxSIbGy+4fyVkaXaC1rNVpqbukUqlyGazJmqffd8nk80wORbSKA2cEp5CmoL+/n7iOGZ4aJAoynN74hb1eo0oiigUCgb5fP5MpT4lz3PxXId/WbuiemcfngMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4e29cdfb7b1bfe4f1d1a1bb8e5432537/e4706/homematic-EN_11.avif 230w", "/en/static/4e29cdfb7b1bfe4f1d1a1bb8e5432537/d1af7/homematic-EN_11.avif 460w", "/en/static/4e29cdfb7b1bfe4f1d1a1bb8e5432537/7f308/homematic-EN_11.avif 920w", "/en/static/4e29cdfb7b1bfe4f1d1a1bb8e5432537/e1c99/homematic-EN_11.avif 1380w", "/en/static/4e29cdfb7b1bfe4f1d1a1bb8e5432537/2babf/homematic-EN_11.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4e29cdfb7b1bfe4f1d1a1bb8e5432537/a0b58/homematic-EN_11.webp 230w", "/en/static/4e29cdfb7b1bfe4f1d1a1bb8e5432537/bc10c/homematic-EN_11.webp 460w", "/en/static/4e29cdfb7b1bfe4f1d1a1bb8e5432537/966d8/homematic-EN_11.webp 920w", "/en/static/4e29cdfb7b1bfe4f1d1a1bb8e5432537/445df/homematic-EN_11.webp 1380w", "/en/static/4e29cdfb7b1bfe4f1d1a1bb8e5432537/fad48/homematic-EN_11.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4e29cdfb7b1bfe4f1d1a1bb8e5432537/81c8e/homematic-EN_11.png 230w", "/en/static/4e29cdfb7b1bfe4f1d1a1bb8e5432537/08a84/homematic-EN_11.png 460w", "/en/static/4e29cdfb7b1bfe4f1d1a1bb8e5432537/c0255/homematic-EN_11.png 920w", "/en/static/4e29cdfb7b1bfe4f1d1a1bb8e5432537/b1001/homematic-EN_11.png 1380w", "/en/static/4e29cdfb7b1bfe4f1d1a1bb8e5432537/29007/homematic-EN_11.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4e29cdfb7b1bfe4f1d1a1bb8e5432537/c0255/homematic-EN_11.png",
              "alt": "homematic",
              "title": "homematic",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=preset&-act=goto&-number=1&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=preset&-act=goto&-number=2&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=manualrec&-act=on&-time=60&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?/cmd=manualrec&-act=off&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <GoToPreset3 mdxType="GoToPreset3" />
    <p>{`In this example both cameras look at each other - and keep an eye on what is happening behind the other camera. This position is stored as `}<strong parentName="p">{`Position 1`}</strong>{` (`}<em parentName="p">{`number=0`}</em>{`) and set to be the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Features/PTZ/"
      }}>{`Park Position`}</a>{`. When the left camera detects something, the right one turns around towards the potential intruder - stored as `}<strong parentName="p">{`Position 2-8`}</strong>{` (`}<em parentName="p">{`number=1-7`}</em>{`) - and starts a recording. And vice versa...`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "10-scan-for-noise-sources",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#10-scan-for-noise-sources",
        "aria-label": "10 scan for noise sources permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`10. Scan for Noise Sources`}</h3>
    <p>{`For example, when triggering the audio detection on your camera, you probably do not know the location of the source of the noise. Here you can trigger a horizontal (hscan) or vertical (vscan) search scan. This must then be manually stopped again with the desired delay:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4795ff639fd15c9c5354a834b0b5b60b/29007/homematic-EN_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQElEQVQoz4WPSW/TUBRG/ddY8RPgJ7BnwQKJHUKCDZvSrhFIsKJCggrUATrQkCZt2oQOTpy0je3EdhMn8fSGOAfFlSqQirjS0Xvve/ce6Rqz2YzbgBlJEjMcDggCn1EYEgQBo9Ho+n/el+fX/DFn8J+aq6M4ZRzFxf0m/EcZatRGhW3UqIMctBC+ibxqIQITOWwhww4j/4JB/5xsMO+1UKmN7tTQ9RLaqqHtBmpoFQ5D9A8o6FVRAxM17qOjABU6CL9B2N7m3CpjdRvkQQ3pVhFnm8ijFeTHl8jlBbL62rXDO8CQ/hHSrxeBHl8wTSfkImaahKihSbe+SuVom2OzjFn+xMTdR699QO8swfI98p2nzBIL6R0ydxVC4R0hgzrp5U8mzS2i9g8mrS1St0rmVKjUdzk+3SPs7BLZVfTeV5KNBdqLd6m/e0Btd4XpVQPhHc5XrjFHerVibdFcQzZXEdY60jsgdvY5b52w+X2TXqvMtFdGODXk9hK/Fu/w/vl9Hj96SGxXUF4NI3VLZG6JpL9H0l4n2X1LUl0mLr1B2Ft0u4dUOw4bDYu6eYhqrpHZJdKTz3D6msqXV7x49oQr6xvC28PIphGpjtB5hJ7FKLIb5m+hJvi+x3g8JhUTNDEqj5CzhJwZdu+cptUgGDrkJBj9LMXNBL2+RefiBM/v0LVNLu0zbLfJZfcML7jg0m7SdcwC221hO00cd04LqUN0PilOI5tK0qlCypgkHaN0ipDxX0iVFNyWZyJC65RpLgp+Aw9wLjhNDnDgAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4795ff639fd15c9c5354a834b0b5b60b/e4706/homematic-EN_09.avif 230w", "/en/static/4795ff639fd15c9c5354a834b0b5b60b/d1af7/homematic-EN_09.avif 460w", "/en/static/4795ff639fd15c9c5354a834b0b5b60b/7f308/homematic-EN_09.avif 920w", "/en/static/4795ff639fd15c9c5354a834b0b5b60b/e1c99/homematic-EN_09.avif 1380w", "/en/static/4795ff639fd15c9c5354a834b0b5b60b/2babf/homematic-EN_09.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4795ff639fd15c9c5354a834b0b5b60b/a0b58/homematic-EN_09.webp 230w", "/en/static/4795ff639fd15c9c5354a834b0b5b60b/bc10c/homematic-EN_09.webp 460w", "/en/static/4795ff639fd15c9c5354a834b0b5b60b/966d8/homematic-EN_09.webp 920w", "/en/static/4795ff639fd15c9c5354a834b0b5b60b/445df/homematic-EN_09.webp 1380w", "/en/static/4795ff639fd15c9c5354a834b0b5b60b/fad48/homematic-EN_09.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4795ff639fd15c9c5354a834b0b5b60b/81c8e/homematic-EN_09.png 230w", "/en/static/4795ff639fd15c9c5354a834b0b5b60b/08a84/homematic-EN_09.png 460w", "/en/static/4795ff639fd15c9c5354a834b0b5b60b/c0255/homematic-EN_09.png 920w", "/en/static/4795ff639fd15c9c5354a834b0b5b60b/b1001/homematic-EN_09.png 1380w", "/en/static/4795ff639fd15c9c5354a834b0b5b60b/29007/homematic-EN_09.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4795ff639fd15c9c5354a834b0b5b60b/c0255/homematic-EN_09.png",
              "alt": "Homematic",
              "title": "Homematic",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=ptzctrl&-step=0&-act=hscan&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=ptzctrl&-step=0&-act=vscan&-usr={USERNAME}&-pwd={PASSWORD}
     /ptzctrl.cgi?-step=&-act=stop
     ``` */}
    <PtzCtrl mdxType="PtzCtrl" />
    <p>{`Alternatively you can use the tour function to move between pre-defined positions `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Features/PTZ_Tour/"
      }}>{`specified in the WebUI`}</a>{` to a maximum of 50 times (except for the model IN-9020 Full HD, which will run the programmed tour until you stop it manually):`}</p>
    {/* ```bash
     /param.cgi?cmd=ptzctrl&-step=0&-act=tour&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <PtzCtrlTour mdxType="PtzCtrlTour" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "11-manually-deactivate-all-alarm-functions-for-all-your-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#11-manually-deactivate-all-alarm-functions-for-all-your-cameras",
        "aria-label": "11 manually deactivate all alarm functions for all your cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`11. Manually deactivate all Alarm functions for all your Cameras`}</h3>
    <p>{`If you have activated more than one alarm trigger in your camera - PIR sensor, alarm areas, audio detection, alarm input - you can activate and deactivate them all at once via the alarm schedule without addressing each trigger individually:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3365f8d447df79eaa5bc401ded3b6574/29007/homematic-EN_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACSUlEQVQoz2WRy04UQRRAe2VcGF2oP0CiqGsXij/gwgTQxC8wLoyJK+M/qYmuEIkwiBCVkQEWKK+ZAZlXz/Sjqrqre3r6cUzXYDShkpv7qHOr7sO6suBxc9lnYsnnWsXnesXn0rzLhQ8uF+ddzs+5XF2Q3Fk9ZrKyzcSSza1lyY2Kz2TFZ2LRMzmXP3qcm3Ox7n+TPKyOZbqqeLCumFpT3PsaMLUmuf1F8Hgj5MlWm+n1HWarfWaqATNVxfSPgNma5tFGyN1VYcSCnDOnSCEKSuOfX5zFSBNGoYJRchrIsFoqZun7BmvbP1EZpGlKu9vjoN7AHjiMspwsy7B1wkptm0p1EzfJKYqCk1abw3rd6CTNGGUZ1mb9Ny+fPeXT4iLOCHSg6PVsbNvG7veJtCbNCxoDwasXz3n39o3hwjCk3+8bpmRLvyzG6qiIvUYDO4xpuYJASnPpeR7DOMb3faQQuHHKbqNJVwaceBIpfLTWhoujCCGEsa0iz4mBOAclpWklSnOEjhmVcysKXM/jLzcsxlwZNznRkDgvjG8e9OIRcZKakQZKYsuQTpwZ/0inKB3he55JyNKMYQ5SKUSoOQrHecc6Q4QRwvexTpyQ18t10qwwre382kVHsQHLpTSaTQOWlb9fqbNna1QYsLe/j1CB4TwhOTism/FYSZJSqbVp2LGZQ6fTQUppxHUcer0eSgqatubzVocgSol0MOaEQEiJ57p0u91xy/3BgIHjMBg4ZlutVotWuz3Wp+KU9457qh2z3ZJp/8eVdvn5H7ijHMo7jltIAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3365f8d447df79eaa5bc401ded3b6574/e4706/homematic-EN_10.avif 230w", "/en/static/3365f8d447df79eaa5bc401ded3b6574/d1af7/homematic-EN_10.avif 460w", "/en/static/3365f8d447df79eaa5bc401ded3b6574/7f308/homematic-EN_10.avif 920w", "/en/static/3365f8d447df79eaa5bc401ded3b6574/e1c99/homematic-EN_10.avif 1380w", "/en/static/3365f8d447df79eaa5bc401ded3b6574/2babf/homematic-EN_10.avif 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3365f8d447df79eaa5bc401ded3b6574/a0b58/homematic-EN_10.webp 230w", "/en/static/3365f8d447df79eaa5bc401ded3b6574/bc10c/homematic-EN_10.webp 460w", "/en/static/3365f8d447df79eaa5bc401ded3b6574/966d8/homematic-EN_10.webp 920w", "/en/static/3365f8d447df79eaa5bc401ded3b6574/445df/homematic-EN_10.webp 1380w", "/en/static/3365f8d447df79eaa5bc401ded3b6574/fad48/homematic-EN_10.webp 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3365f8d447df79eaa5bc401ded3b6574/81c8e/homematic-EN_10.png 230w", "/en/static/3365f8d447df79eaa5bc401ded3b6574/08a84/homematic-EN_10.png 460w", "/en/static/3365f8d447df79eaa5bc401ded3b6574/c0255/homematic-EN_10.png 920w", "/en/static/3365f8d447df79eaa5bc401ded3b6574/b1001/homematic-EN_10.png 1380w", "/en/static/3365f8d447df79eaa5bc401ded3b6574/29007/homematic-EN_10.png 1600w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3365f8d447df79eaa5bc401ded3b6574/c0255/homematic-EN_10.png",
              "alt": "Homematic",
              "title": "Homematic",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ```bash
     /param.cgi?cmd=setscheduleex&-ename=md&-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week1=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week2=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week3=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week4=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week5=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week6=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-usr={USERNAME}&-pwd={PASSWORD}
     /param.cgi?cmd=setscheduleex&-ename=md&-week0=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week1=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week2=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week3=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week4=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week5=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-week6=PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <SetSchedule mdxType="SetSchedule" />
    <p>{`In this command, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`week0 - week6`}</code>{` stands for Monday through Sunday and each `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`P`}</code>{` is half an hour of the day the alarm schedule is active (`}<em parentName="p">{`48 x 30min = 24h`}</em>{`). An `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`N`}</code>{` equals a 30-minute period in which it is disabled. The schedule activates / deactivates all alarm actions.`}</p>
    <p><strong parentName="p">{`Update November 2019`}</strong>{` `}<em parentName="p">{`(only for Full HD Camera models)`}</em></p>
    <p>{`Due to a change in the web user interface the alarm schedule is now stored in a new variable called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`setbackupschedule`}</code>{`. If you use the command above, using just `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`setscheduleex`}</code>{`, the active alarm schedule will be changed but this change will not be visible inside the webUI. It is recommended to always switch both variables to prevent a de-sync:`}</p>
    {/* ```bash
     /param.cgi?cmd=setscheduleex&-ename=md&-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week1=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week2=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week3=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week4=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week5=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week6=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&cmd=setbackupschedule&-ename=md&-week0=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week1=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week2=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week3=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week4=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week5=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-week6=NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN&-usr={USERNAME}&-pwd={PASSWORD}
     ``` */}
    <SetScheduleBak mdxType="SetScheduleBak" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "12-switch-between-pir-and-software-motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#12-switch-between-pir-and-software-motion-detection",
        "aria-label": "12 switch between pir and software motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12. Switch between PIR and Software Motion Detection`}</h3>
    <p>{`Most of our new cameras have both an internal, software-based motion detection and a hardware PIR (passive infrared) heat sensor to detect motion of objects that have a different surface temperature as the ambient. In most cases we recommend that you `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Actions/"
      }}>{`link both`}</a>{` as they have different strengths and weaknesses and the coupling can filter many sources of false alerts.`}</p>
    <p>{`But there are situation where one of those two ways to detect motion simply fails and does not provide any filtering effect anymore. Examples are:`}</p>
    <ul>
      <li parentName="ul">{`The PIR is exposed to direct sunlight through most of the day. Shadows and temperature changes trigger regular false alarms.`}</li>
      <li parentName="ul">{`The Motion Detection Areas are constantly triggered at night due to the light from passing cars.`}</li>
      <li parentName="ul">{`etc.`}</li>
    </ul>
    <p>{`We can use our smarthome to automatically deactivate the problematic sensor during times where we know that their accuracy is affected. Since we probably want to use both sensors during times of the day when both work perfectly, we also have to switch the coupling on and off, accordingly.`}</p>
    <p><strong parentName="p">{`Link between PIR and Alarm Areas`}</strong></p>
    {/* ```bash
     /param.cgi?cmd=setmdalarm&-aname=type&-switch=on
     /param.cgi?cmd=setmdalarm&-aname=type&-switch=off
     ``` */}
    <SetMdAlarm mdxType="SetMdAlarm" />
    <p><strong parentName="p">{`Alarm Areas Activation / Deactivation`}</strong></p>
    {/* ```bash
     /param.cgi?cmd=setmdattr&-name=1&-enable=1&cmd=setmdattr&-name=2&-enable=1&cmd=setmdattr&-name=3&-enable=1&cmd=setmdattr&-name=4&-enable=1
     /param.cgi?cmd=setmdattr&-name=1&-enable=0&cmd=setmdattr&-name=2&-enable=0&cmd=setmdattr&-name=3&-enable=0&cmd=setmdattr&-name=4&-enable=0
     ``` */}
    <SetMdAttr4 mdxType="SetMdAttr4" />
    <p><strong parentName="p">{`PIR Activation / Deactivation`}</strong></p>
    {/* ```bash
     /param.cgi?cmd=setpirattr&-pir_enable=1
     /param.cgi?cmd=setpirattr&-pir_enable=0
     ``` */}
    <SetPirAttr mdxType="SetPirAttr" />
    <p>{`In case of the IN-6014 HD or IN-9020/10 Full HD (also camera models with attached external PIR sensors) you have to switch the alarm input instead:`}</p>
    {/* ```bash
     /param.cgi?cmd=setioattr&-io_enable=1
     /param.cgi?cmd=setioattr&-io_enable=0
     ``` */}
    <SetIoAttr mdxType="SetIoAttr" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      